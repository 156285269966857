(function() {
'use strict';
/**
  aflGrid Directive
  Intended to be a component, but usage of nested directives
  require compile precedure to pass the argument
*/
angular.module('aerosApp').
  constant('AFL_GRID', {
    calculateDefaultWidth: calculateDefaultWidth,
    getDefaultWidth: _.partial(calculateDefaultWidth, 198),
    defaultActionWidth: 198,
    widths: {
        "All": [470, 470],
        "OTDR": [235, 235, 235, 235],
        "OLTS": [470, 470],
        "Inspection": [470, 470],
        "Certification": [470, 470]
    }
  });

function calculateDefaultWidth(offset, numColumns) {
  return Math.floor((1140 - offset) / numColumns);
}

}());
